<template>
  <main class="about" v-if="!loading">
    <div v-if="$store.state.info.bio" class="about-item">
      <div class="about-content" v-html="about.text"></div>
      <div class="" v-html="about.contact"></div>
      <form
        action="https://gmail.us3.list-manage.com/subscribe/post?u=34e6c114cff7f124c7a24f8e0&amp;id=742d62daab"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate about-newsletter"
        target="_blank"
        novalidate
      >
        <div class="about-title">Newsletter:</div>
        <input
          type="email"
          value=""
          v-model="email"
          name="EMAIL"
          class="email"
          placeholder="Email..."
          required
        />
        <div style="position: absolute; left: -5000px" aria-hidden="true">
          <input
            type="text"
            name="b_f61bc318c8b6d3429d69fea3b_fe519af25c"
            tabindex="-1"
            value=""
          />
        </div>
        <input
          v-if="email.length > 0"
          type="submit"
          value="Subscribe"
          name="button"
          class="button"
        />
      </form>
    </div>
    <div class="about-item" v-if="$store.state.info.press && press.length > 0">
        <div>
            <div class="about-title hover press" @click="pressActive = !pressActive">Selected Press</div>
            <a v-if="about.pdf && about.pdf.length > 0" :href="about.pdf[0].url" target="_blank">(Download Press PDF)</a>
        </div>
      <div v-if="pressActive">
        <div v-for="key in press" :key="key.year" class="about-cv">
            <div class="about-year" @click="pressYear[key.year] = true">{{ key.year || 'N/A' }}</div>
            <div class="about-entries">
                <div
                    v-for="(press, index) in key.entries"
                    :key="'p-' + index"
                    class="about-cownt"
                >
                    <a v-if="press.type === 'link'" :href="press.link" target="_blank">{{
                    press.title
                    }}</a>
                    <a v-if="press.type === 'file' && press.file" :href="press.file.url" target="_blank">{{
                    press.title
                    }}</a>
                </div>
            </div>
        </div>
      </div>  
    </div>
    <div v-for="key in cv" :key="key.id" class="about-item">
      <template v-if="$store.state.info[parseKey(key.key)]">
        <div>
            <div class="about-title hover press" @click="section[key.key] = !section[key.key]">{{ fixTitle(key.key) }}</div>
            <a v-if="key.key == 'solo exhibitions' && about.cvpdf && about.cvpdf.length > 0" :href="about.cvpdf[0].url" target="_blank">(Download Full CV PDF)</a>
        </div>
        <div v-if="section[key.key]">
            <div v-for="year in key.years" :key="year.id" class="about-cv">
              <div class="about-year">
                {{ year.year }}
              </div>
              <div class="about-entries">
                <div
                  v-for="project in year.projects"
                  :key="project.id"
                  v-html="project.title"
                ></div>
              </div>
            </div>
        </div>
      </template>
    </div>
    <div v-if="$store.state.info.impressum" class="about-item">
      <div class="about-title hover" @click="impressumActive = !impressumActive">Imprint</div>
      <div v-if="impressumActive" v-html="about.impressum"></div>
    </div>
  </main>
</template>

<script>
import { groupBy, orderBy, map } from "lodash";
export default {
  name: "about",
  data() {
    return {
        loading: true,
        impressumActive: false,
        pressActive: false,
        section: {
            press: false,
            impressum: false,
            "solo exhibitions": false,
            "group exhibitions": false,
            performances: false,
            filmography: false,
            bibliography: false,
            publications: false,
            awards: false,
            residencies: false,
            collectives: false,
            teaching: false,
            writing: false,
            other: false,
        },
        email: "",
        pressYear: {}
    };
  },
  created() {
    if (Object.keys(this.$store.state.pages).length > 0) {
      this.loading = false;
    }
  },
  mounted() {
    this.$root.$on("about-loaded", () => {
      this.loading = false;
    });
  },
  computed: {
    about() {
      return this.$store.state.pages;
    },
    cv() {
      let groupCv = groupBy(this.about.cv, (project) => project.type);
      let mapCv = map(groupCv, function (value, key) {
        let years = groupBy(value, (entry) => entry.year);
        let mapYears = map(years, (value, key) => ({
          year: key,
          projects: value,
        }));
        var sortedYears = orderBy(mapYears, ["year"], ["desc"]);
        return {
          key: key,
          years: sortedYears,
        };
      });
      return mapCv;
    },
    press() {
        let group = groupBy(this.about.press, (project) => project.year);
        let mapYears = map(group, function (value, key) {
            return {
                year: key,
                open: false,
                entries: value,
            };
        });
        // remove empty years
        mapYears = mapYears.filter((year) => year.year);
        let sorted = orderBy(mapYears, ["year"], ["desc"]);
        return sorted;
    }
  },
  methods: {
    parseKey(key) {
      var replaced = key.replace(/ /g, "_");
      return replaced;
    },
    fixTitle(title) {
      var replaced = title.replace("solo", "selected solo");
      replaced = replaced.replace("group", "selected group");
      return replaced;
    }
  },
};
</script>

<style lang="scss">
.about {
  box-sizing: border-box;
  width: 100%;
  min-height: 50%;
  background: white;
  column-count: 1;
  column-gap: calc(4 * var(--lh));
  @media only screen and (max-width: 768px) {
    column-count: 1;
  }
  .about-item {
    // margin-bottom: var(--lh);
    break-inside: avoid;
    &:first-of-type {
        margin-bottom: var(--lh);
    }
    .about-newsletter {
      display: flex;
      align-items: flex-start;
      input {
        font-family: "Libre Caslon Display", sans-serif;
        font-size: var(--fs);
        border: 0;
        margin-left:0.25rem;
        border-radius: 0;
        outline: none;
        flex: 0 0 auto;
        margin-right: var(--lh);
        transition: border 0.5s;
      }
      input[type="submit"] {
        flex: 0 0 auto;
        border: 0;
        background: rgba(0, 0, 0, 0.05);
        transition: background 0.5s;
        margin-right: 0;
        cursor: pointer;
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }

    p {
      margin-bottom: var(--lh);
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .about-title {
      text-transform: capitalize;
      letter-spacing: 0.05vw;
      &.hover:hover {
        font-style: italic;
      }
      &.press {
        display: inline-block;
        margin-right:1ch;
      }
    }
    .about-news {
      margin-bottom: var(--lh);
    }
    .about-cv {
      display: flex;
      margin-bottom: var(--lh);
      .about-year {
        width: 6ch;
        flex:0 0 auto;
      }
    }
  }
}
</style>
